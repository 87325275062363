import styled from "styled-components";
import { COLORS } from "../../theme/colors";

export const Container = styled.div`
    background-color: ${COLORS.WHITE};
`;

export const ContainerFooter = styled.div`
    background-color: ${COLORS.WHITE};

    padding-top: 1rem;
    padding-bottom: 1rem;
`;

export const ContainerCopyright = styled.div`
    background-color: ${COLORS.PRIMARY};

    padding-top: .5rem;
    padding-bottom: .5rem;
`;

export const Title = styled.h1`
    color: ${COLORS.PRIMARY};

    margin-bottom: .5rem;

    font-weight: 600;
    font-size: 1.6rem;
`;

export const TitleSub = styled.h3`
    color: ${COLORS.SECONDARY};

    margin-bottom: .5rem;

    font-weight: 300;
    font-size: 1rem;
`;

export const Contato = styled.div`
    margin-bottom: .5rem;
`;

export const ContatoTitle = styled.h4`
    color: ${COLORS.PRIMARY};

    margin-bottom: .5rem;

    font-weight: 600;
    font-size: .8rem;
`;

export const ContatoInfo = styled.div`
    display: flex;
    align-items: center;

    gap: .5rem;
`;

export const ContatoIcone = styled.div`
    color: ${COLORS.PRIMARY};
`;

export const ContatoTexto = styled.p`
    color: ${COLORS.PRIMARY};
    margin-bottom: 0;
`;

export const NavFooter = styled.div`
    display: flex;
    justify-content: center;
    align-items: center; 

    gap: .5rem;
`;

export const NavFooterItem = styled.div`
`;

export const NavFooterItemLink = styled.a`
    background-color: ${COLORS.WHITE};

    cursor: pointer;

    color: ${COLORS.PRIMARY};

    display: flex;
    justify-content: center;
    align-items: center; 

    border-radius: 2rem;

    padding: .3rem;

    &:hover{
        background-color: ${COLORS.SECONDARY};
        color: ${COLORS.WHITE};

        transition: all .2s ease-in-out;
    }
`;