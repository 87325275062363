import { useEffect, useState } from "react";
import { capitalizeFirstLetter, formatDocumento } from "../../utils/functions";
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { InputPassword } from "../../components/Input";
import { toast } from "react-toastify";

import * as yup from "yup";

import Fieldset from "../../components/Fieldset";
import HeaderPage from "../../components/HeaderPage";
import useApi from "../../hooks/useApi";
import moment from "moment";
import Button from "../../components/Button";

const schema = yup.object({
    SenhaAtual: yup.string().required("Informe a senha atual"),
    Senha: yup.string().required("Informe a nova senha"),
    ConfirmaNovaSenha: yup.string().oneOf([yup.ref('Senha'), null], 'Senha inválida').required("Informe a nova senha"),
});

const Configuracoes = () => {
    const api = useApi();

    const [nome, setNome] = useState('');
    const [documento, setDocumento] = useState('');
    const [dataNascimento, setDataNascimento] = useState('');
    const [celular, setCelular] = useState('');
    const [email, setEmail] = useState('');
    const [endereco, setEndereco] = useState('');

    const { handleSubmit, control, reset, formState: { errors } } = useForm({
        resolver: yupResolver(schema)
    });

    useEffect(() => {

        setNome(sessionStorage.getItem('Nome'));
        setEmail(sessionStorage.getItem('Email'));
        setEndereco(sessionStorage.getItem('Endereco'));
        setDataNascimento(sessionStorage.getItem('DataNascimento'));
        setDocumento(sessionStorage.getItem('Documento'));

        if (sessionStorage.getItem('Celular')) {
            setCelular(sessionStorage.getItem('Celular'))
        }

    }, []);

    const onSubmit = async (formData) => {
        let { response } = await api.post(`cliente/AtualizarSenha/${sessionStorage.getItem('ClientesId')}`, formData);

        if (response.status !== 200) {
            toast.error('Não foi possível atualizar a senha, verifique os dados informados');
            return false
        }

        toast.success('Senha atualizada com sucesso');
        reset({ SenhaAtual: '', Senha: '', ConfirmaNovaSenha: '' });
    }

    return (
        <div className="container">
            <HeaderPage title="Configurações" />

            <div className="row mb-4">
                <div className="col-12 col-sm-4 mb-3">
                    <Fieldset title="Nome" content={capitalizeFirstLetter(nome)} />
                </div>

                <div className="col-12 col-sm-4 mb-3">
                    <Fieldset title="CPF" content={documento ? formatDocumento(documento) : ''} />
                </div>

                <div className="col-12 col-sm-4 mb-3">
                    <Fieldset title="Data Nascimento" content={dataNascimento ? moment(dataNascimento).format('DD/MM/YYYY') : ''} />
                </div>

                {celular &&
                    <div className="col-12 col-sm-4 mb-3">
                        <Fieldset title="Celular" content={celular} />
                    </div>
                }

                <div className="col-12 col-sm-4 mb-3">
                    <Fieldset title="E-mail" content={email} />
                </div>

                <div className="col-12">
                    <Fieldset title="Endereço" content={capitalizeFirstLetter(endereco)} />
                </div>
            </div>

            <HeaderPage title="Alterar Senha" />

            <form onSubmit={handleSubmit(onSubmit)} autoComplete="false" autoCapitalize="false">
                <div className="row align-items-start">
                    <div className="col-12 col-sm-3">
                        <InputPassword
                            name="SenhaAtual"
                            placeholder="Senha atual"
                            required
                            control={control}
                            error={errors.SenhaAtual}
                        />
                    </div>

                    <div className="col-12 col-sm-3">
                        <InputPassword
                            name="Senha"
                            placeholder="Nova senha"
                            required
                            control={control}
                            error={errors.Senha}
                        />
                    </div>

                    <div className="col-12 col-sm-3">
                        <InputPassword
                            name="ConfirmaNovaSenha"
                            placeholder="Confirme a nova senha"
                            required
                            control={control}
                            error={errors.ConfirmaNovaSenha}
                        />
                    </div>
                    <div className="col-12 col-sm-3 pb-3">
                        <Button load={api.load} type="submit" fullsize title="Atualizar Senha" />
                    </div>
                </div>
            </form>
        </div>
    );
}

export default Configuracoes;
