import { Outlet } from "react-router-dom";
import { BG_LOGIN, LOGOMARCA } from "../../images";
import { Container, ContainerConta, Placeholder, Content, Image } from './styles';

const TemplateConta = () => {
    return (
        <Container>
            <ContainerConta>
                <Placeholder bg={BG_LOGIN}></Placeholder>
                <Content>
                    <Image src={LOGOMARCA} alt="Premium Clube Área do Associado" />
                    <Outlet />
                </Content>
            </ContainerConta>
        </Container>
    )
}

export default TemplateConta;