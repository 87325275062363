import { COLORS } from "../../theme/colors";

const LoadPage = () => {
    return (
        <div className="d-flex justify-content-center align-items-center" style={{ width: '100%', minHeight: '350px' }}>
            <div className="spinner-border" style={{ width: '1rem', height: '1rem', color: COLORS.SECONDARY }} role="status">
                <span className="visually-hidden">Loading...</span>
            </div>
        </div >
    )
}

export default LoadPage;