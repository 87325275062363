import styled from "styled-components";
import { COLORS } from "../../theme/colors";

export const Container = styled.div`
    position: absolute;

    flex-direction: column;
    justify-content: center;
    align-items: center;

    top: 0;
    
    width: 100%;
    height: 100%;

    z-index: 999;
    
    background-color: ${COLORS.BLACK_OPACITY50};
`

export const Form = styled.form`
    width: 300px;

    background-color: ${COLORS.WHITE};

    border-radius: .8rem;

    padding: 1rem;
`