import styled from "styled-components";

export const Title = styled.small`
    font-size: .7rem;
    font-weight:300;

    margin-bottom: 0;
`

export const Content = styled.p`
    font-size: 1rem;
    font-weight: bold;    

    margin-bottom: 0;
`