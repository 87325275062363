import { createSlice } from "@reduxjs/toolkit";

const slice = createSlice({
  name: "global",
  initialState: {
    Authorization: "",
    Cliente: {
      Nome: "",
      UsuariosId: ""
    },
    Veiculos: []
  },
  reducers: {
    setAuthorization: (state, action) => {
      state.Authorization = action.payload;
    },
    setCliente: (state, action) => {
      state.Cliente = action.payload;
    },
    setVeiculos: (state, action) => {
      state.Veiculos = action.payload
    },
  },
});

export const { setAuthorization, setCliente, setVeiculos } = slice.actions;
export default slice.reducer;
