import styled from "styled-components";
import { COLORS } from "../../theme/colors";

export const Container = styled.fieldset`
    border: .12rem solid ${COLORS.GRAY};
    border-radius: .5rem;

    position: relative;

    padding-top: 15px;
    padding-left: 10px;
`;

export const Title = styled.legend`    
    position: absolute;
    top: -.8rem;
    left: .5rem;

    width: 170px;

    font-size: .8rem;
    font-weight: 700;

    padding: 0 5px;
    
    background-color: ${COLORS.WHITE};
    color: ${COLORS.GRAY_DARK};

    margin: 0;
`;

export const Texto = styled.p`
    margin: 0;

    color: ${COLORS.GRAY_DARK};
    
    font-size: .8rem;
    font-weight: 600;
`;

export const List = styled.ul`
    list-style: none;
    padding: 0;
`;

export const ListItems = styled.li`
    font-size: .8rem;
    font-weight: 600;

    color: ${COLORS.GRAY_DARK};
`;