import styled from 'styled-components';

export const ListaFaturas = styled.div`
    height: 300px;

    overflow-y: ${props =>  props.length > 4 ? 'scroll' : 'hidden'};
    overflow-x: hidden;

    position: relative;

    &::-webkit-scrollbar {
        width: .5rem;
    }

    &::-webkit-scrollbar-track {
        background: #f1f1f1; 
    }
    
    &::-webkit-scrollbar-thumb {
        background: #888; 
    }

    &::-webkit-scrollbar-thumb:hover {
        background: #555; 
    }
`