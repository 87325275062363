import { useState } from "react";
import { Controller } from "react-hook-form";
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import { CustomLabel, CustomInput, CustomTextArea, CustomSelect, CustomInputMask } from '../../theme/input';
import { Container, ContainerPassword, InvalidFeedback, Button } from './styles';

export const Input = ({ name, control, label, required, error, placeholder, props }) => {
    return (
        <Container>
            {label && <CustomLabel htmlFor={name}>{label} {required && <span className="text-danger">*</span>}</CustomLabel>}

            <Controller
                name={name}
                control={control}
                render={({ field }) => <CustomInput value={field.value || ''} onChange={field.onChange} error={!error} placeholder={placeholder} className="form-control" {...props} />}
            />

            {error && <InvalidFeedback>{error.message}</InvalidFeedback>}
        </Container>
    )
}

export const InputMasked = ({ name, control, label, required, error, placeholder, props }) => {
    return (
        <Container>
            {label && <CustomLabel htmlFor={name}>{label} {required && <span className="text-danger">*</span>}</CustomLabel>}

            <Controller
                name={name}
                control={control}
                render={({ field }) => <CustomInputMask
                    value={field.value || ''}
                    onChange={field.onChange}
                    error={!error}
                    placeholder={placeholder}
                    className="form-control"
                    {...props}
                />}
            />

            {error && <InvalidFeedback>{error.message}</InvalidFeedback>}
        </Container>
    )
}


export const TextArea = ({ name, control, label, required, error, placeholder, props }) => {
    return (
        <Container>
            {label && <CustomLabel htmlFor={name}>{label} {required && <span className="text-danger">*</span>}</CustomLabel>}

            <Controller
                name={name}
                control={control}
                render={({ field }) => <CustomTextArea value={field.value || ''} onChange={field.onChange} error={error} placeholder={placeholder} className="form-control" {...props}></CustomTextArea>}
            />

            {error && <InvalidFeedback>{error.message}</InvalidFeedback>}
        </Container>
    )
}

export const Select = ({ name, items, control, label, required, error, placeholder, props }) => {
    return (
        <Container>
            {label && <CustomLabel htmlFor={name}>{label} {required && <span className="text-danger">*</span>}</CustomLabel>}

            <Controller
                name={name}
                control={control}
                render={({ field }) =>
                    <CustomSelect value={field.value || ''} onChange={field.onChange} error={error} className="form-control" {...props}>
                        <option value="">- Selecione -</option>
                        {items && items.length > 0 && items.map((item, i) => <option key={i} value={item.value}>{item.label}</option>)}
                    </CustomSelect>}
            />

            {error && <InvalidFeedback>{error.message}</InvalidFeedback>}
        </Container>
    )
}

export const InputPassword = ({ name, control, label, required, error, placeholder, props }) => {
    const [open, setOpen] = useState(false);
    return (
        <ContainerPassword>
            {label && <CustomLabel htmlFor={name}>{label} {required && <span className="text-danger">*</span>}</CustomLabel>}

            <Controller
                name={name}
                control={control}
                render={({ field }) => <CustomInput value={field.value || ''} onChange={field.onChange} error={!error} type={open ? 'text' : 'password'} placeholder={placeholder} className="form-control" {...props} />}
            />

            <Button error={!error} onClick={() => setOpen(!open)}>
                {open ? <AiOutlineEyeInvisible /> : <AiOutlineEye />}
            </Button>

            {error && <InvalidFeedback>{error.message}</InvalidFeedback>}
        </ContainerPassword>
    )
}