export const LOGOMARCA = require('./logomarca.png');
export const LOGOMARCA_MONO = require('./logo-premium.png');

export const ICONE_SUA_PROTECAO = require('./icone-protecao.png');
export const ICONE_FINANCEIRO = require('./icone-financeiro.png');
export const ICONE_HISTORICO = require('./icone-historico.png');
export const ICONE_DOWNLOAD = require('./icone-download.png');
export const ICONE_INDICOU_GANHOU = require('./icone-indicacao.png');
export const ICONE_CONFIGURACOES = require('./icone-configuracao.png');

export const LOGOMARCA_CLUBE_BENEFICIO = require('./logo-clube-beneficios.png');
export const BG_LOGIN = require('./bg-login.jpg');