import { useState } from "react";
import { useEffect } from "react";
import { formatMoney } from "../../utils/functions";
import { CustomSelect } from "../../theme/input";

import Fieldset from "../../components/Fieldset";
import HeaderPage from "../../components/HeaderPage";
import useApi from '../../hooks/useApi';
import LoadPage from "../../components/LoadPage";

const SuaProtecao = () => {
    const api = useApi();
    const [veiculos, setVeiculos] = useState([]);
    const [veiculoSelecionado, setVeiculoSelecionado] = useState(null);

    useEffect(() => {
        (async () => {
            const res = await api.get('ViewCarrosClientes/detalhes');
            if (res.response.status === 200) {
                setVeiculos(res.data);

                if (res.data.length > 0) {
                    setVeiculoSelecionado(res.data[0]);
                }
            }
        })()
    }, []);

    return api.load ? <LoadPage /> : (
        <div className="container">
            <HeaderPage title="Sua Proteção" />

            {veiculos.length > 1 && (
                <div className="row mb-5">
                    <div className="col-12 col-sm-3">
                        <label>Veículo(s)</label>
                        <CustomSelect
                            onChange={({ target }) => setVeiculoSelecionado(JSON.parse(target.value))}
                            value={JSON.stringify(veiculoSelecionado)}
                            className="form-select"
                        >
                            {veiculos.map(veiculo => <option key={veiculo.id} value={JSON.stringify({ ...veiculo })}>{veiculo.placa}</option>)}
                        </CustomSelect>
                    </div>
                </div>)}

            <div className="row">
                <div className="col-12 col-sm-3 mb-3">
                    <Fieldset title="Modelo" content={veiculoSelecionado?.modelo} />
                </div>

                <div className="col-12 col-sm-3 mb-3">
                    <Fieldset title="Marca" content={veiculoSelecionado?.marca} />
                </div>

                <div className="col-12 col-sm-3 mb-3">
                    <Fieldset title="Ano" content={veiculoSelecionado?.ano} />
                </div>

                <div className="col-12 col-sm-3 mb-3">
                    <Fieldset title="Categoria" content={veiculoSelecionado?.tipo_nome} />
                </div>

                <div className="col-12 col-sm-3 mb-3">
                    <Fieldset title="Parcela" content={formatMoney(veiculoSelecionado?.mensalidade || 0)} />
                </div>

                <div className="col-12 col-sm-3 mb-3">
                    <Fieldset title="Cota de Participação" content={formatMoney(veiculoSelecionado?.franquia || 0)} />
                </div>

                <div className="col-12 col-sm-3 mb-3">
                    <Fieldset title="Valor Molicar" content={formatMoney(veiculoSelecionado?.fipe || 0)} />
                </div>

                <div className="col-12 col-sm-12 mb-3">
                    <Fieldset title="Opcionais" items={veiculoSelecionado?.opcionais} />
                </div>
            </div>

        </div>
    )
}

export default SuaProtecao;