import { useEffect, useState } from "react";
import { FaDownload } from 'react-icons/fa';
import { Card, CardBody, CardFooter, CardLink } from './styles';

import HeaderPage from "../../components/HeaderPage";
import LoadPage from "../../components/LoadPage";

import useApi from "../../hooks/useApi";

const Donwloads = () => {
    const { load, get } = useApi();
    const [arquivos, setArquivos] = useState([]);

    useEffect(() => {
        (async () => {
            let res = await get('downloads/consultar/?ativo=1&tipo=1');
            if (res.response.status === 200) {
                setArquivos(Object.values(res.data));
            }
        })()
    }, [])

    return load ? <LoadPage /> : (
        <div className="container">
            <HeaderPage title="Downloads" />

            <div className="row">
                {arquivos && arquivos.map((item, i) => (
                    <div className="col-12 col-sm-12 col-md-6 col-lg-4" key={i}>
                        <Card>
                            <CardBody>
                                {item.nome}
                            </CardBody>
                            <CardFooter>
                                <CardLink href={item.url} target="_blank">
                                    Download <FaDownload />
                                </CardLink>
                            </CardFooter>
                        </Card>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default Donwloads;