import { useEffect } from "react";

import useApi from "../../hooks/useApi";
import LoadPage from "../../components/LoadPage";

const ClubeBeneficios = () => {
    const api = useApi();

    useEffect(() => {
        (async () => {
            const { data } = await api.get('ClubeBeneficios/consultar/');

            if (!data.erro) {
                const login = await api.get('ClubeBeneficios/EfetuarLogin');

                if (login.data && login.data.Url) {
                    window.location.href = login.data.Url;
                }

            } else {
                const cadastro = await api.post('ClubeBeneficios/inserir', { Email: sessionStorage.getItem('Email') });
                const login = await api.get('ClubeBeneficios/EfetuarLogin');

                if (login.data && login.data.Url) {
                    window.location.href = login.data.Url;
                }
            }
        })();
    }, {})

    return <LoadPage />;
}

export default ClubeBeneficios;