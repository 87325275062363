import { useNavigate } from 'react-router-dom';
import { LOGOMARCA_MONO } from '../../images';
import { Container, Image, NavLink } from './styles'

const Header = () => {
    const navigate = useNavigate();

    const handleLogout = () => {    
        sessionStorage.clear();
        navigate('/conta', { replace: true });
    }

    return (
        <Container>
            <div className='container'>
                <div className='row align-items-center'>
                    <div className='col-12 col-sm-1'>
                        <Image src={LOGOMARCA_MONO} alt="Premium Clube - Área Associado" />
                    </div>
                    <div className='col-12 col-sm-11 d-flex justify-content-end'>
                        <NavLink onClick={() => handleLogout()}>
                            Sair
                        </NavLink>
                    </div>
                </div>
            </div>
        </Container>
    )
}

export default Header;