import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { CustomLink, Form } from './styles';
import { Input, InputPassword } from "../../../components/Input";
import { toast } from "react-toastify";
import { useNavigate } from 'react-router-dom';

import * as yup from "yup";
import useApi from '../../../hooks/useApi';
import ButtonCustom from '../../../components/Button';

const schema = yup.object({
    UsuariosLogin: yup.string().required("Informe o seu E-mail, Placa ou CPF"),
    UsuariosSenha: yup.string().required("Informe a sua Senha ou Data de Nascimento"),
});

const Login = () => {
    const api = useApi();
    const navigate = useNavigate();

    const { handleSubmit, control, formState: { errors } } = useForm({
        resolver: yupResolver(schema)
    });

    const onSubmit = async (formData) => {
        let { response, data } = await api.post('Login/Login', formData);

        if (response.status !== 200) {
            toast.error(data.erro);
            return false;
        }

        sessionStorage.setItem('_PCUP', data.alterar_senha || 0);
        sessionStorage.setItem('ClientesId', data.cliente_id)
        sessionStorage.setItem('Authorization', data.Authorization)
        sessionStorage.setItem('Veiculos', JSON.stringify(data.veiculos));

        sessionStorage.setItem('Nome', data.nome);
        sessionStorage.setItem('Documento', data.cpf);
        sessionStorage.setItem('DataNascimento', data.nascimento);
        sessionStorage.setItem('Email', data.email.toLowerCase());

        if (data.celular) {
            sessionStorage.setItem('Celular', `(${data.ddd_celular}) ${data.celular}`);
        }

        if (data.telefone) {
            sessionStorage.setItem('Telefone', `(${data.ddd_telefone}) ${data.telefone}`);
        }

        sessionStorage.setItem('Endereco', `${data.endereco} ${data.numero}, ${data.bairro} ${data.cidade} - ${data.estado}`);

        navigate("/", { replace: true });
    };

    return (
        <Form onSubmit={handleSubmit(onSubmit)} autoComplete="false" autoCapitalize="false">
            <Input
                name="UsuariosLogin"
                label="E-mail, Placa ou CPF"
                placeholder="E-mail, Placa ou CPF"
                required
                control={control}
                error={errors.UsuariosLogin}
            />

            <InputPassword
                name="UsuariosSenha"
                label="Senha"
                placeholder="Senha ou Data de Nascimento"
                required
                control={control}
                error={errors.UsuariosSenha}
            />

            <ButtonCustom type="submit" load={api.load} fullsize title="Entrar" />

            <CustomLink to="recuperar-senha">Esqueceu a senha?</CustomLink>
        </Form>
    )
}

export default Login;