import styled from "styled-components";
import { COLORS } from "../../theme/colors";

export const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items:center;

    height: 100vh;
`;

export const ContainerConta = styled.div`
    display: flex;
    justify-content: space-between;
    align-items:center;

    border-radius: .5rem;

    box-shadow: 0 0 20px 2px ${COLORS.BLACK_OPACITY25};

    height: 600px;
    width: 50%;

    @media (max-width: 1024px) {
        width: 95%;
    }

    @media (min-width: 1025px) and (max-width: 1366px) {
        width: 75%;
    }
`;

export const Placeholder = styled.div`
    display: flex;
    justify-content: center;
    align-items:center;

    border-radius: .5rem 0 0 .5rem;
    
    background-image: url(${props => props.bg});
    background-size: cover;
    
    width: 50%;
    height: 100%;

    @media (max-width: 768px) {
        display: none;
    }
`;

export const Content = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items:center;

    padding: 15px;

    width: 50%;
    height: 100%;

    @media (max-width: 768px) {
        width: 100%;
    }
`;

export const Image = styled.img`
    width: 50%;
`;