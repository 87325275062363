import styled from "styled-components";
import { Link } from 'react-router-dom';
import { COLORS } from "../../../theme/colors";

export const Form = styled.form`
    width: 100%;

    display: flex;
    flex-direction: column;
`;

export const CustomLink = styled(Link)`
    text-decoration: none;

    margin-top: 1rem;

    color: ${COLORS.PRIMARY};

    &:hover{
        color: ${COLORS.SECONDARY};
    }
`
