import { useState } from "react";
import { Container, Form } from "./styles";
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { InputPassword } from "../Input";
import { toast } from "react-toastify";

import * as yup from "yup";
import useApi from "../../hooks/useApi";
import Button from "../../components/Button";
import HeaderPage from "../HeaderPage";

const schema = yup.object({
    SenhaAtual: yup.string().required("Informe a senha atual"),
    Senha: yup.string().required("Informe a nova senha"),
    ConfirmaNovaSenha: yup.string().oneOf([yup.ref('Senha'), null], 'Senha inválida').required("Informe a nova senha"),
});

const ModalPassword = () => {
    const api = useApi();
    const [open, setopen] = useState(sessionStorage.getItem('_PCUP') && sessionStorage.getItem('_PCUP') == 1 ? true : false);

    const { handleSubmit, control, reset, formState: { errors } } = useForm({
        resolver: yupResolver(schema)
    });

    const onSubmit = async (formData) => {
        let { response } = await api.post(`cliente/AtualizarSenha/${sessionStorage.getItem('ClientesId')}`, formData);

        if (response.status !== 200) {
            toast.error('Não foi possível atualizar a senha, verifique os dados informados');
            return false
        }

        sessionStorage.setItem('_PCUP', 0);
        setopen(false);
        toast.success('Senha atualizada com sucesso');
        reset({ SenhaAtual: '', Senha: '', ConfirmaNovaSenha: '' });
    }

    return (
        <Container style={{ display: open ? 'flex' : 'none' }}>
            <Form onSubmit={handleSubmit(onSubmit)} autoComplete="false" autoCapitalize="false">
                <HeaderPage title="Alterar Senha" />

                <div className="row align-items-start">
                    <div className="col-12">
                        <InputPassword
                            name="SenhaAtual"
                            placeholder="Senha atual"
                            required
                            control={control}
                            error={errors.SenhaAtual}
                        />
                    </div>

                    <div className="col-12">
                        <InputPassword
                            name="Senha"
                            placeholder="Nova senha"
                            required
                            control={control}
                            error={errors.Senha}
                        />
                    </div>

                    <div className="col-12">
                        <InputPassword
                            name="ConfirmaNovaSenha"
                            placeholder="Confirme a nova senha"
                            required
                            control={control}
                            error={errors.ConfirmaNovaSenha}
                        />
                    </div>

                    <div className="col-12">
                        <Button load={api.load} type="submit" fullsize title="Atualizar Senha" />
                    </div>
                </div>
            </Form>
        </Container>
    )
}

export default ModalPassword;