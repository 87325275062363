import { Navigate, useRoutes } from "react-router-dom";

import TemplateAdmin from "../layouts/TemplateAdmin";
import TemplateConta from "../layouts/TemplateConta";

import Login from "../views/Conta/Login";
import RecuperarSenha from "../views/Conta/RecuperarSenha";

import SuaProtecao from "../views/SuaProtecao";
import Financeiro from "../views/Financeiro";
import Donwloads from "../views/Donwloads";
import Configuracoes from "../views/Configuracoes";
import ClubeBeneficios from "../views/ClubeBeneficios/inde";

const Routers = () => {
    const isLogged = sessionStorage.getItem('Authorization') || null;

    const routes = useRoutes([
        {
            path: '/',
            element: isLogged ? <TemplateAdmin /> : <Navigate to="/conta" />,
            children: [
                { index: true, element: <SuaProtecao /> },
                { path: 'sua-protecao', element: <SuaProtecao /> },
                { path: 'financeiro', element: <Financeiro /> },                
                { path: 'downloads', element: <Donwloads /> },                
                { path: 'clube-de-beneficios', element: <ClubeBeneficios /> },                
                { path: 'configuracoes', element: <Configuracoes /> },
            ]
        },
        {
            path: '/conta',
            element: <TemplateConta />,
            children: [
                { index: true, element: <Login /> },
                { path: 'recuperar-senha', element: <RecuperarSenha /> },
            ]
        }

    ]);

    return routes;
}

export default Routers;