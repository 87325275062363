export const COLORS = {
    PRIMARY: '#1e3668',
    SECONDARY: '#f47920',

    GRAY:'#dfe4ea',
    GRAY_LIGHT:'#f1f2f6',
    GRAY_DARK:'#57606f',

    DANGER:'#ff5252',
    SUCCESS:'#00c853',

    WHITE: '#fff',
    BLACK: '#000',

    WHITE_OPACITY50: 'rgba(255, 255, 255, .5)',
    BLACK_OPACITY50: 'rgba(0, 0, 0, .5)',
    BLACK_OPACITY25: 'rgba(0, 0, 0, .25)',
}