import styled from "styled-components";
import InputMask from 'react-input-mask';

import { COLORS } from "./colors";

export const CustomLabel = styled.label`
    font-size: .8rem;
    font-weight: 700;
`;

export const CustomInput = styled.input`
    border-color: ${props => !props.error ? COLORS.DANGER : ''};    
    border-radius: .5rem;

    &::placeholder {
        font-size: .8rem;
        font-weight: 700;
    }
`;


export const CustomInputMask = styled(InputMask)`
    border-color: ${props => !props.error ? COLORS.DANGER : ''};    
    border-radius: .5rem;

    &::placeholder {
        font-size: .8rem;
        font-weight: 700;
    }
`;

export const CustomSelect = styled.select`
    border-color: ${props => props.error ? COLORS.DANGER : ''};    
    border-radius: .5rem;    

    &::placeholder {
        font-size: .8rem;
        font-weight: 700;
    }
`;


export const CustomTextArea = styled.textarea`
    border-color: ${props => props.error ? COLORS.DANGER : ''};    
    border-radius: .5rem;    

    &::placeholder {
        font-size: .8rem;
        font-weight: 700;
    }
`;