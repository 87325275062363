import styled from "styled-components";
import { COLORS } from "./colors";

export const Card = styled.div`
    background-color: ${COLORS.WHITE};

    width: 100%;

    margin-bottom: 1rem;

    border:1px solid ${COLORS.GRAY};
    border-radius: .6rem;
`

export const CardBody = styled.div`
    padding: .5rem;
`