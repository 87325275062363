import { useSelector } from 'react-redux';
import { capitalizeFirstLetter } from '../../utils/functions';
import { Container, Title } from './styles';

const HeaderSub = () => {
    const Cliente = sessionStorage.getItem('Nome') || '';

    return (
        <Container>
            <div className='container'>
                <Title>Bem-Vindo <small>{capitalizeFirstLetter(Cliente)}</small></Title>
            </div>
        </Container>
    )
}

export default HeaderSub;