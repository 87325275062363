import { Container, Title, Texto, List, ListItems } from './styles';

const Fieldset = ({ title, content, items }) => {
    return (
        <Container>
            <Title>{title}</Title>
            {content &&
                <Texto>{content}</Texto>
            }

            {items &&
                <List>
                    {
                        items.map(item => (
                            <ListItems key={item.nome}>- {item.nome}</ListItems>
                        ))
                    }
                </List>
            }
        </Container>
    )
}

export default Fieldset;