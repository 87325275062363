import { BrowserRouter } from "react-router-dom"
import { ToastContainer } from "react-toastify"

import Routers from "../routers"
import GlobalStyle from "../theme/global"

const Views = () => {
    return (
        <>
            <GlobalStyle />
            <ToastContainer position="top-center" theme="colored" closeOnClick autoClose={3000} />
            
            <BrowserRouter>
                <Routers />
            </BrowserRouter>
        </>
    )
}

export default Views