import styled from "styled-components";
import { COLORS } from "../../theme/colors";

export const Container = styled.h1`
    color: ${COLORS.SECONDARY};

    font-size: 1rem;
    font-weight: 600;

    margin-bottom: 2rem;
`;