import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { CustomLink, Form } from './styles';
import { Input } from "../../../components/Input";
import { toast } from "react-toastify";
import { useNavigate } from 'react-router-dom';

import * as yup from "yup";
import useApi from '../../../hooks/useApi';
import ButtonCustom from '../../../components/Button';
import { checkCNPJ, checkCPF, onlyNumbers } from '../../../utils/functions';

const schema = yup.object({
    Documento: yup.string().required("Informe o seu CPF ou o CNPJ"),
});

const RecuperarSenha = () => {
    const api = useApi();
    const navigate = useNavigate();

    const { handleSubmit, control, reset, formState: { errors } } = useForm({
        resolver: yupResolver(schema)
    });

    const onSubmit = async (formData) => {
        const documento = onlyNumbers(formData.Documento);

        let check = true;

        if (documento.length === 11) {
            check = checkCPF(documento);
        } else if (documento.length === 14) {
            check = checkCNPJ(documento);
        }

        if (!check) {
            toast.error('CPF ou CNPJ inválido');
            return false;
        }

        const { data } = await api.post('cliente/ResetarSenha', { cpf: formData.Documento });

        if (!data.erro) {
            reset({ Documento: '' })
            toast.success(`Uma nova senha foi enviada para o e-mail ${data.Email}`);
        }else{
            toast.error(data.erro);
        }
    };

    return (
        <Form onSubmit={handleSubmit(onSubmit)} autoComplete="false" autoCapitalize="false">
            <div className='mb-3'>
                <Input
                    name="Documento"
                    label="Documento"
                    placeholder="Digite o CPF ou o CNPJ"
                    required
                    control={control}
                    error={errors.Documento}
                />
            </div>

            <ButtonCustom type="submit" load={api.load} fullsize title="Recuperar" />

            <CustomLink to="/conta">Retornar à página de login!</CustomLink>
        </Form>
    )
}

export default RecuperarSenha;