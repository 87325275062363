import styled from "styled-components";
import { COLORS } from "../../theme/colors";

export const Container = styled.div`
    background-color: ${COLORS.PRIMARY};
    padding-top: .5rem;
    padding-bottom: .6rem;
`;
export const Image = styled.img`
    object-fit: contain;

    width: 150px;
    height: auto;
`;

export const NavLink = styled.div`
    background-color:${COLORS.SECONDARY};

    padding-left: 2rem;
    padding-right: 2rem;

    border-radius: 2rem;

    color: ${COLORS.WHITE};

    cursor: pointer;
`;