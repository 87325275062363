import moment from 'moment';
import numeral from 'numeral';
import 'numeral/locales/pt-br';

export const capitalizeFirstLetter = (string) => {
    const stringInArray = string.split(' ');
    let newString = '';

    stringInArray.forEach(word => {
        newString += word.toLowerCase().charAt(0).toUpperCase() + word.toLowerCase().slice(1) + ' ';
    });

    return newString;
}

export function formatMoney(value = 0) {
    numeral.locale('en');
    const newValue = numeral(value ? value : 0.0).value();
    numeral.locale('pt-br');
    return numeral(newValue).format('$ 0,0.00');
}

export function formatDocumento(value = '') {
    const documento = value.replace(/\D/g, '');

    if (documento.length === 11) {
        return value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, '$1.$2.$3-$4');
    } else {
        return value.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, '$1.$2.$3/$4-$5');
    }
}

function passToWeekDay(dataToCheck) {
    const data = moment(dataToCheck).add(7, 'days'); // pode pagar até 7 dias após vencimento
    const weekIndex = data.isoWeekday();

    if (weekIndex === 6 || weekIndex === 7) {
        return (moment(data.add(8 - weekIndex, 'days')).format('YYYY-MM-DD'));
    }

    return data;
}

export function checkDueDate(datavencimento) {
    const week_datavencimento = passToWeekDay(datavencimento);
    return moment().isAfter(week_datavencimento, 'days');
}

export function onlyNumbers(text) {
    return text.replace(/\.|-|\/|\s|\(|\)/g, '');
}

export function checkCPF(cpf) {
    if (cpf == '') {
        return false;
    }
    cpf = cpf.replace(/\./gi, '').replace(/-/gi, '');
    var isValid = true;
    var sum;
    var rest;
    var i;
    i = 0;
    sum = 0;
    if (
        cpf.length != 11 ||
        cpf == '00000000000' ||
        cpf == '11111111111' ||
        cpf == '22222222222' ||
        cpf == '33333333333' ||
        cpf == '44444444444' ||
        cpf == '55555555555' ||
        cpf == '66666666666' ||
        cpf == '77777777777' ||
        cpf == '88888888888' ||
        cpf == '99999999999'
    ) {
        isValid = false;
    }
    for (i = 1; i <= 9; i++) {
        sum = sum + parseInt(cpf.substring(i - 1, i), 10) * (11 - i);
    }
    rest = (sum * 10) % 11;
    if (rest === 10 || rest === 11) {
        rest = 0;
    }
    if (rest !== parseInt(cpf.substring(9, 10), 10)) {
        isValid = false;
    }
    sum = 0;
    for (i = 1; i <= 10; i++) {
        sum = sum + parseInt(cpf.substring(i - 1, i), 10) * (12 - i);
    }
    rest = (sum * 10) % 11;
    if (rest === 10 || rest === 11) {
        rest = 0;
    }
    if (rest !== parseInt(cpf.substring(10, 11), 10)) {
        isValid = false;
    }
    return isValid;
}

export function checkCNPJ(cnpj) {
    cnpj = cnpj.replace(/[^\d]+/g, '');

    if (cnpj == '') return false;

    if (cnpj.length != 14) return false;

    // Elimina CNPJs invalidos conhecidos
    if (
        cnpj == '00000000000000' ||
        cnpj == '11111111111111' ||
        cnpj == '22222222222222' ||
        cnpj == '33333333333333' ||
        cnpj == '44444444444444' ||
        cnpj == '55555555555555' ||
        cnpj == '66666666666666' ||
        cnpj == '77777777777777' ||
        cnpj == '88888888888888' ||
        cnpj == '99999999999999'
    )
        return false;

    // Valida DVs
    let tamanho = cnpj.length - 2;
    let numeros = cnpj.substring(0, tamanho);
    let digitos = cnpj.substring(tamanho);
    let soma = 0;
    let pos = tamanho - 7;
    for (let i = tamanho; i >= 1; i--) {
        soma += Number(numeros.charAt(tamanho - i)) * pos--;
        if (pos < 2) pos = 9;
    }
    let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
    if (resultado != Number(digitos.charAt(0))) return false;

    tamanho = tamanho + 1;
    numeros = cnpj.substring(0, tamanho);
    soma = 0;
    pos = tamanho - 7;
    for (let i = tamanho; i >= 1; i--) {
        soma += Number(numeros.charAt(tamanho - i)) * pos--;
        if (pos < 2) pos = 9;
    }
    resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
    if (resultado != Number(digitos.charAt(1))) return false;

    return true;
}