import { Button } from "../../theme/buttons";
import { Card, CardBody } from "../../theme/cards";
import { formatMoney } from "../../utils/functions";
import { Title, Content } from "./styles";
import { MdPrint } from 'react-icons/md'

import moment from "moment";

const CardFaturas = ({ boleto }) => {
    // const dueDate = checkDueDate(boleto.dataoriginal, boleto.datavencimento);
    // const status = boleto.datapagamento ? (boleto.local === '11' ? 4 : 1) : dueDate ? 3 : 2;

    return (
        <Card>
            <CardBody>
                <div className="row justify-content-start align-items-center">
                    <div className="col-3">
                        {boleto.datapagamento
                            ? <span className="badge bg-success">Pago em {moment(boleto.datapagmento).format('DD/MM/YYYY')}</span>
                            : <span className="badge bg-warning">Em aberto</span>
                        }
                    </div>

                    <div className="col-3 text-center">
                        {boleto.tipo && parseInt(boleto.tipo) === 9 &&
                            <span className="badge bg-info">Evento</span>
                        }

                        {boleto.tipo && parseInt(boleto.tipo) === 4 &&
                            <span className="badge bg-primary">Adesão</span>
                        }

                        {boleto.tipo && parseInt(boleto.tipo) === 5 &&
                            <span className="badge bg-secondary">Mensalidade</span>
                        }
                    </div>

                    <div className="col-2">
                        <Title>Data Vencimento</Title>
                        <Content>{moment(boleto.dataoriginal || boleto.datavencimento).format('DD/MM/YYYY')}</Content>
                    </div>

                    <div className="col-2">
                        <Title>Valor</Title>
                        <Content>{boleto.datapagamento ? formatMoney(boleto.valorpago) : formatMoney(boleto.valor)}</Content>
                    </div>

                    {!boleto.datapagamento &&
                        <div className="col d-flex justify-content-end">
                            <Button type="button" onClick={() => window.open(boleto.detalhes.url)}>
                                <MdPrint /> Imprimir
                            </Button>
                        </div>
                    }
                </div>
            </CardBody>
        </Card>
    )
}

export default CardFaturas;