import { useState, useEffect } from "react";
import { CustomSelect } from "../../theme/input";
import { Button } from "../../theme/buttons";
import { ListaFaturas } from "./styles";

import CardFaturas from "../../components/CardFaturas";
import HeaderPage from "../../components/HeaderPage";
import LoadPage from "../../components/LoadPage";
import useApi from "../../hooks/useApi";
import moment from "moment";

const Financeiro = () => {
    const { get, load } = useApi();

    const [veiculos, setVeiculos] = useState([]);
    const [boletos, setBoletos] = useState([]);
    const [placa, setPlaca] = useState({});
    const [filtro, setFiltro] = useState('&datapagamento=NULL&order=dataoriginal asc');

    useEffect(() => {
        let dadosVeiculos = sessionStorage.getItem("Veiculos") || null;
        dadosVeiculos = dadosVeiculos ? JSON.parse(dadosVeiculos) : [];

        if (dadosVeiculos.length > 0) {
            setPlaca(dadosVeiculos[0]);
            setVeiculos(dadosVeiculos);
        }
    }, []);

    useEffect(() => {
        (async () => {
            let boletos = [];

            if (placa && placa.placa) {
                const query = `placa=${placa?.placa}&cadastro=${placa?.carros_clientes_id}&dataoriginal<=${moment().add(3, 'month').format('YYYY-MM-DD')}${filtro}`;

                const ViewBoletos = await get(`ViewBoletos/consultarBoletos/?${query}`);
                const ViewBoletosEventos = await get(`ViewBoletosEventos/consultarBoletos/?${query}`);
                const ViewBoletosAdesao = await get(`ViewBoletosAdesao/consultarBoletos/?${query}`);

                boletos = [...ViewBoletos.data, ...ViewBoletosAdesao.data, ...ViewBoletosEventos.data];
            }

            setBoletos(boletos);
        })()
    }, [placa, filtro]);

    return load ? <LoadPage /> : (
        <div className="container">
            <HeaderPage title="Financeiro" />

            <div className="row mb-4">
                <div className="col-12 col-sm-3">
                    <CustomSelect
                        onChange={({ target }) => setPlaca(JSON.parse(target.value))}
                        value={JSON.stringify(placa)}
                        className="form-select"
                    >
                        {veiculos && veiculos.length > 0 && veiculos.map((veiculo, i) => <option key={i} value={JSON.stringify({ ...veiculo })}>{veiculo.placa}</option>)}
                    </CustomSelect>
                </div>

                <div className="col-12 col-sm-3">
                    <Button
                        type="button"
                        fullsize
                        selected={filtro === '&datapagamento=NULL&order=dataoriginal asc' ? true : false}
                        onClick={() => setFiltro('&datapagamento=NULL&order=dataoriginal asc')}
                    >
                        Em aberto
                    </Button>
                </div>

                <div className="col-12 col-sm-3">
                    <Button
                        type="button"
                        fullsize
                        selected={filtro === '&order=dataoriginal desc' ? true : false}
                        onClick={() => setFiltro('&order=dataoriginal desc')}
                    >
                        Todos
                    </Button>
                </div>

                <div className="col-12 col-sm-3">
                    <Button
                        type="button"
                        fullsize
                        selected={filtro === '&datapagamento!=NULL&local!=11&order=dataoriginal desc' ? true : false}
                        onClick={() => setFiltro('&datapagamento!=NULL&local!=11&order=dataoriginal asc')}
                    >
                        Pago
                    </Button>
                </div>
            </div>

            <ListaFaturas length={boletos.length} >
                {boletos && boletos.length > 0 && boletos.map((boleto, i) => (
                    <CardFaturas boleto={boleto} />
                ))}
            </ListaFaturas>
        </div>
    );
}

export default Financeiro;