import styled from "styled-components";
import { COLORS } from "./colors";

export const Button = styled.button`
    border: 0;
    border-radius: 2rem;    

    display:flex;
    align-items: center;
    justify-content: center;
    gap: .3rem;

    color: ${COLORS.WHITE};

    padding: .5rem 1rem;

    width: ${props => props.fullsize ? '100%' : 'auto'};

    background-color: ${props => props.selected && props.selected === true ? COLORS.SECONDARY : COLORS.PRIMARY};

    &:hover{    
        transition: all .2s ease-in-out;
        background-color: ${COLORS.SECONDARY};            
    }
`