import { Container, Title, TitleSub, ContainerFooter, ContainerCopyright, NavFooter, NavFooterItem, NavFooterItemLink, Contato, ContatoTitle, ContatoInfo, ContatoIcone, ContatoTexto } from './styles';
import { FaFacebook, FaInstagram, FaYoutube, FaLinkedinIn, FaPhoneAlt, FaWhatsapp } from "react-icons/fa";
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { Input, Select, TextArea } from '../Input';
import { useState, useEffect } from 'react';
import { capitalizeFirstLetter } from '../../utils/functions';

import * as yup from "yup";
import Button from '../Button';
import useApi from '../../hooks/useApi';
import { toast } from 'react-toastify';
import ModalPassword from '../ModalPassword';

const schema = yup.object({
    Placa: yup.string().required("Selecione uma placa"),
    Mensagem: yup.string().required("Insira sua mensagem"),
});

const Footer = () => {
    const api = useApi();
    const [veiculos, setVeiculos] = useState([]);

    const { handleSubmit, control, reset, formState: { errors } } = useForm({
        resolver: yupResolver(schema)
    });

    useEffect(() => {
        let dadosVeiculos = sessionStorage.getItem("Veiculos") || null;
        dadosVeiculos = dadosVeiculos ? JSON.parse(dadosVeiculos) : [];

        if (dadosVeiculos.length > 0) {
            setVeiculos(dadosVeiculos);
        }

        reset({ Nome: capitalizeFirstLetter(sessionStorage.getItem('Nome')), Email: sessionStorage.getItem('Email') })
    }, []);

    const onSubmit = async (formData) => {
        const placa = JSON.parse(formData.Placa);
        console.log(placa);

        const body = {
            Cliente: placa.carros_clientes_id,
            Mensagem: formData.Mensagem,
            placa: placa.placa
        };

        const { data } = await api.post('clientes_vendas/FaleConosco', 'POST', body);

        if (!data.erro) {
            toast.success('Mensagem enviada com sucesso!');
        } else {
            toast.error('Erro ao enviar mensagem, tente novamente!');
        }
    };


    return (
        <Container>
            <ContainerFooter>
                <div className="container">
                    <Title>Fale Conosco</Title>
                    <div className="row">
                        <div className="col-12 col-sm-12 col-md-6">
                            <div className="row">
                                <div className="col-12 col-sm-6 col-md-6">
                                    <TitleSub>Central de Relacionamento</TitleSub>

                                    <Contato>
                                        <ContatoTitle>Para capitais</ContatoTitle>
                                        <ContatoInfo>
                                            <ContatoIcone><FaPhoneAlt /></ContatoIcone>
                                            <ContatoTexto>4007-2420</ContatoTexto>
                                        </ContatoInfo>
                                    </Contato>

                                    <Contato>
                                        <ContatoTitle>Para demais localidades</ContatoTitle>
                                        <ContatoInfo>
                                            <ContatoIcone><FaPhoneAlt /></ContatoIcone>
                                            <ContatoTexto>0800 609 0331</ContatoTexto>
                                        </ContatoInfo>
                                    </Contato>
                                </div>

                                <div className="col-12 col-sm-6 col-md-6">
                                    <TitleSub>Assistência 24h</TitleSub>

                                    <Contato>
                                        <ContatoTitle>WhatsApp</ContatoTitle>
                                        <ContatoInfo>
                                            <ContatoIcone><FaWhatsapp /></ContatoIcone>
                                            <ContatoTexto>0800 609 0331</ContatoTexto>
                                        </ContatoInfo>
                                    </Contato>

                                    <Contato>
                                        <ContatoTitle>Para demais localidades</ContatoTitle>
                                        <ContatoInfo>
                                            <ContatoIcone><FaPhoneAlt /></ContatoIcone>
                                            <ContatoTexto>0800 609 6779</ContatoTexto>
                                        </ContatoInfo>
                                    </Contato>
                                </div>
                            </div>
                        </div>

                        <div className="col-12 col-sm-12 col-md-6">
                            <form onSubmit={handleSubmit(onSubmit)} autoComplete="false" autoCapitalize="false">
                                <div className="row">
                                    <div className="col-12 col-sm-6">
                                        <div className="form-group">
                                            <div className="mb-3">
                                                <Input
                                                    name="Nome"
                                                    placeholder="Nome"
                                                    required
                                                    disabled
                                                    control={control}
                                                    error={errors.Nome}
                                                />
                                            </div>

                                            <div className="mb-3">
                                                <Input
                                                    name="Email"
                                                    placeholder="E-mail"
                                                    required
                                                    disabled
                                                    control={control}
                                                    error={errors.Email}
                                                />
                                            </div>

                                            <div className="mb-3">
                                                <Select
                                                    name='Placa'
                                                    className='form-select'
                                                    control={control}
                                                    error={errors.Placa}
                                                    items={veiculos.map(veiculo => ({ value: JSON.stringify({ ...veiculo }), label: veiculo.placa }))}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-12 col-sm-6">
                                        <div style={{ marginBottom: '2.7rem' }}>
                                            <TextArea
                                                name='Mensagem'
                                                placeholder="Mensagem"
                                                rows="3"
                                                control={control}
                                                error={errors.Mensagem}
                                            ></TextArea>
                                        </div>

                                        <Button load={api.load} title="Enviar Mensagem" fullsize />
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </ContainerFooter>

            <ContainerCopyright>
                <NavFooter>
                    <NavFooterItem>
                        <NavFooterItemLink target="_blank" href='https://www.youtube.com/channel/UCCRvg9MYF6b6s42SyJva1Dw'>
                            <FaYoutube />
                        </NavFooterItemLink>
                    </NavFooterItem>

                    <NavFooterItem>
                        <NavFooterItemLink target="_blank" href='https://www.instagram.com/premiumclubeoficial/'>
                            <FaInstagram />
                        </NavFooterItemLink>
                    </NavFooterItem>

                    <NavFooterItem>
                        <NavFooterItemLink target="_blank" href='https://www.facebook.com/premiumclubeoficial/'>
                            <FaFacebook />
                        </NavFooterItemLink>
                    </NavFooterItem>

                    <NavFooterItem>
                        <NavFooterItemLink target="_blank" href='https://www.linkedin.com/company/premium-clube/mycompany/?viewAsMember=true'>
                            <FaLinkedinIn />
                        </NavFooterItemLink>
                    </NavFooterItem>
                </NavFooter>
            </ContainerCopyright>

            <ModalPassword />
        </Container>
    )
}

export default Footer;