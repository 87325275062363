import { useState } from "react";
import { API_URI, HEADER, TOKEN } from "../utils/contants";

const useApi = (basePath = API_URI) => {
    const [load, setLoad] = useState(false);

    const get = async (url = '') => {
        setLoad(true);

        let header = HEADER;

        header.Authorization = TOKEN;

        if (window.sessionStorage.getItem('Authorization'))
            header.Authorization = window.sessionStorage.getItem('Authorization');

        const options = {
            headers: header,
            method: 'GET'
        }

        const response = await fetch(basePath + url, options);
        const data = await response.json();

        setLoad(false);
        return { data, response };
    }

    const post = async (url = '', formData = {}) => {
        setLoad(true);

        let header = HEADER;

        header.Authorization = TOKEN;

        if (window.sessionStorage.getItem('Authorization'))
            header.Authorization = window.sessionStorage.getItem('Authorization');

        const options = {
            headers: header,
            method: 'POST',
            body: JSON.stringify(formData)
        }

        const response = await fetch(basePath + url, options);
        const data = await response.json();

        setLoad(false);
        return { data, response };
    }

    return { load, get, post };
}

export default useApi;