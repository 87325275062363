import styled from "styled-components";
import { COLORS } from "../../theme/colors";

export const Card = styled.div`
    background-color: ${COLORS.WHITE};

    border: 1px solid ${COLORS.GRAY};
    border-radius: .6rem;
`;

export const CardBody = styled.div`
    padding: 1rem;
    font-size: .8rem;
`;

export const CardFooter = styled.div`
    padding: 1rem;

    background-color: ${COLORS.GRAY_DARK};
    border-radius: 0 0 .6rem .6rem;
`;

export const CardLink = styled.a`
    text-decoration: none;

    color: ${COLORS.WHITE};

    cursor: pointer;

    gap: 10px;

    display: flex;
    flex-direction: row;
    justify-content: end;
    align-items: center;

    &:hover{
        color: ${COLORS.WHITE};
    }

`;