export const PROD = true;
export const API_URI = PROD ? 'https://service-provisorio.premiumclube.org.br/' : 'http://service.hm.premiumclube.org.br/';
export const TOKEN = '07f9341cc8d09862ddc1483b1c44fcfd3424f81a63b8e6ecb629f028d7c1e6dc';

export const HEADER = {
    "Content-Type": "application/json",
    Authorization: TOKEN,
    LocalAcesso: "Cliente",
    HeaderUsuario: window.navigator.userAgent,
};

export const TIPO_FATURAS = {        
    9: 'Evento',
    4: "Adesão"
}

export const STATUS_PAGAMENTO_BOLETO = {
    1: { Nome: 'Pago', Cor: 'success' },
    2: { Nome: 'Em aberto', Cor: 'warning' },
    3: { Nome: 'Vencido', Cor: 'danger' },
    4: { Nome: 'Cancelado', Cor: 'danger' },
}