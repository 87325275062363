import styled from "styled-components";
import { COLORS } from "../../theme/colors";

export const Container = styled.div`
    padding-top: .5rem;
    padding-bottom: .5rem;
`;

export const Title = styled.h1`
    margin: 0;
    
    font-size: 1.5rem;
    font-weight: 600;

    color: ${COLORS.PRIMARY};

    small{
        font-weight: 300;
    }
`;