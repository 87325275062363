import styled from "styled-components";
import { Link } from 'react-router-dom';
import { COLORS } from "../../theme/colors";

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    height: 100vh;
`;

export const WrapperContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;    
`;

export const Sidebar = styled.div`
    display: flex;
    flex-direction: column;
    width: 200px;   
`;

export const NavSidebar = styled.div`
    display: flex;
    flex-direction: column;

    gap: .5rem;
`;

export const NavSidebarItem = styled.div`
    display: flex;
    flex-direction: column;
`;

export const NavSidebarItemLink = styled(Link)`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;

    padding: .5rem;

    border-radius:  .6rem 0 0 .6rem;

    text-decoration: none;

    background-color: ${COLORS.PRIMARY};

    &:hover{
        transition: all .2s ease-in-out;
        background-color: ${COLORS.SECONDARY};
    }
`;

export const NavSidebarItemLinkIcone = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    width: 2rem;
    height: 2rem;
`;

export const NavSidebarItemLinkTexto = styled.p`
    width: 100%;

    margin:0;

    color: ${COLORS.WHITE};

    font-weight: 300;
    font-size: .9rem;
`;

export const Content = styled.div`
    display: flex;

    min-height: 350px;
    width: calc(100% - 200px);

    border-radius: 1rem;

    padding: 15px;

    background-color: ${COLORS.WHITE};
`;

export const Image = styled.img`
    width: 100%;
    height: auto;
`;