import { Button } from '../../theme/buttons';

const Load = () => (
    <div className="spinner-border" style={{ width: '1rem', height: '1rem' }} role="status">
        <span className="visually-hidden">Loading...</span>
    </div>
);

const ButtonCustom = ({ title, load, fullsize, active = false, props }) => {
    return (
        <Button active={active} fullsize={fullsize} disabled={load} {...props}>
            {!load ? title : <Load />}
        </Button>
    )
}

export default ButtonCustom;