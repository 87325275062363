import { Outlet } from "react-router-dom";
import { Container, WrapperContainer, Sidebar, Content, NavSidebar, NavSidebarItem, NavSidebarItemLink, NavSidebarItemLinkIcone, NavSidebarItemLinkTexto, Image } from "./styles";
import { ICONE_CONFIGURACOES, ICONE_DOWNLOAD, ICONE_FINANCEIRO, ICONE_INDICOU_GANHOU, ICONE_SUA_PROTECAO } from "../../images";

import Header from "../../components/Header";
import Footer from '../../components/Footer';
import HeaderSub from "../../components/HeaderSub";

const TemplateAdmin = () => {    
    return (
        <Container>
            <Header />
            <HeaderSub />

            <div className="container pt-3 pb-3">
                <WrapperContainer>
                    <Sidebar>
                        <NavSidebar>
                            <NavSidebarItem>
                                <NavSidebarItemLink to="/sua-protecao">
                                    <NavSidebarItemLinkIcone>
                                        <Image src={ICONE_SUA_PROTECAO} alt="Sua Proteção" />
                                    </NavSidebarItemLinkIcone>
                                    <NavSidebarItemLinkTexto>
                                        Sua proteção
                                    </NavSidebarItemLinkTexto>
                                </NavSidebarItemLink>
                            </NavSidebarItem>

                            <NavSidebarItem>
                                <NavSidebarItemLink to="/financeiro">
                                    <NavSidebarItemLinkIcone>
                                        <Image src={ICONE_FINANCEIRO} alt="Financeiro" />
                                    </NavSidebarItemLinkIcone>
                                    <NavSidebarItemLinkTexto>
                                        Financeiro
                                    </NavSidebarItemLinkTexto>
                                </NavSidebarItemLink>
                            </NavSidebarItem>

                            <NavSidebarItem>
                                <NavSidebarItemLink to="/downloads">
                                    <NavSidebarItemLinkIcone>
                                        <Image src={ICONE_DOWNLOAD} alt="Downloads" />
                                    </NavSidebarItemLinkIcone>
                                    <NavSidebarItemLinkTexto>
                                        Downloads
                                    </NavSidebarItemLinkTexto>
                                </NavSidebarItemLink>
                            </NavSidebarItem>

                            <NavSidebarItem>
                                <NavSidebarItemLink to="/clube-de-beneficios">
                                    <NavSidebarItemLinkIcone>
                                        <Image src={ICONE_INDICOU_GANHOU} alt="Downloads" />
                                    </NavSidebarItemLinkIcone>
                                    <NavSidebarItemLinkTexto>
                                        Clube de Benefícios
                                    </NavSidebarItemLinkTexto>
                                </NavSidebarItemLink>
                            </NavSidebarItem>

                            <NavSidebarItem>
                                <NavSidebarItemLink to="/configuracoes">
                                    <NavSidebarItemLinkIcone>
                                        <Image src={ICONE_CONFIGURACOES} alt="Configurações" />
                                    </NavSidebarItemLinkIcone>
                                    <NavSidebarItemLinkTexto>
                                        Configurações
                                    </NavSidebarItemLinkTexto>
                                </NavSidebarItemLink>
                            </NavSidebarItem>
                        </NavSidebar>
                    </Sidebar>

                    <Content>
                        <Outlet />
                    </Content>
                </WrapperContainer>
            </div>

            <Footer />
        </Container>
    )
}

export default TemplateAdmin;